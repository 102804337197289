import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Button extends Component {
    static propTypes = {
        style: PropTypes.object,
        onClick: PropTypes.func,
        className: PropTypes.string,
    };

    static defaultProps = {
        style: {},
        onClick: () => {},
    };

    render() {
        const { onClick, children, ...validDomProps } = this.props;

        const buttonClassName = classNames({
            [this.props.className]: this.props.className,
        });

        return (
            <button {...validDomProps} onClick={onClick} className={buttonClassName}>{children}</button>
        )
    }
}
