import styles from "./styles/Modal.module.scss";
import classNames from "classnames";
import {CSSTransition} from "react-transition-group";
import {MouseEventHandler} from "react";


const ModalBackdrop = (props: {
    active: boolean
    backgroundColor?: string
    onMouseDown: MouseEventHandler
    opaqueBackground?: boolean
    zIndex?: number
}) => {
    const {active, backgroundColor, onMouseDown, opaqueBackground = true, zIndex} = props;
    const style: {
        zIndex?: number
        backgroundColor?: string
    } = {};

    if (zIndex) {
        style.zIndex = zIndex;
    }
    if (backgroundColor) {
        style.backgroundColor = backgroundColor;
    }

    const backdropContainerClassName = classNames({
        [styles.backdropContainer]: opaqueBackground,
        [styles.backdropContainerNoOpacity]: !opaqueBackground,
    });

    const modalBackdropClassName = classNames({
        [styles.modalBackdrop]: true,
    });

    const transitionClassNames = {
        enter: styles.transitionEnter,
        enterActive: styles.transitionEnterActive,
        enterDone: styles.transitionEnterDone,
        exit: styles.transitionExit,
        exitActive: styles.transitionExitActive,
        exitDone: styles.transitionExitDone,
    };

    return (
        <div className={backdropContainerClassName}>
            <CSSTransition timeout={0} appear={true} in={active} classNames={transitionClassNames}>
                <div className={modalBackdropClassName} onMouseDown={onMouseDown} style={style} />
            </CSSTransition>
        </div>
    );
}

export default ModalBackdrop;