import {createRef, Component, cloneElement, RefObject, PropsWithChildren} from "react";
import {observer} from "mobx-react";

import classNames from "classnames";
import styles from "./styles/Modal.module.scss";
import zIndex from "../../styles/_zIndex.module.scss";
import {CSSTransition} from "react-transition-group";
import Button from "../utilities/Button";
import FontAwesome from "../utilities/FontAwesome";
import {AppContextType, StoreContext} from "../../stores/StoreLoader";
import ModalBackdrop from "./_ModalBackdrop";
import {ErrorBoundary} from "../utilities/ErrorBoundary";

const transitionClassNames = {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    enterDone: styles.transitionEnterDone,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
    exitDone: styles.transitionExitDone,
};

@observer
class Modal extends Component<PropsWithChildren<{
    size: "sm" | "md" | "lg" | "xl" | "sb"
    theme?: "classic" | "transparent"
    index: number
    type: string
    className?: string
    dialogClassName?: string
    containerClassName?: string
    modalClassName?: string
    width?: number
    closeEffect?: () => void,
    transitionClassNames?: object
    closeButtonClassName?: string
    closeButtonComponent?: JSX.Element
}>, {
    active: boolean
    previousFocus: HTMLElement | null
}> {
    static contextType = StoreContext;
    context: AppContextType

    public modalRef: RefObject<HTMLDivElement>;
    public baseZIndex: number;

    static defaultProps = {
        size: "lg",
        type: 'default',
        theme: "classic",
    };

    constructor(props, context) {
        super(props, context);
        this.state = {
            active: true,
            previousFocus: document.activeElement as HTMLElement | null,
        }
        this.modalRef = createRef();
        this.baseZIndex = Number(zIndex["z-index-modal"]);
        this.context = context;
    }

    closeModal = () => {
        this.setState({
                active: false,
            },
            () => {
                setTimeout(this.context.modalStore.removeTop, 500);
            });
    }

    onMouseDown = (event) => {
        const target = event.target;
        if (target === this.refs.modal) {
            event.stopPropagation();
            this.closeModal();
        }
    };

    onKeyDown = (event) => {
        switch (event.key) {
            case 'Esc':
            case 'Escape':
                this.closeModal();
                break;
        }
    }

    focusFirstElement = () => {
        this.modalRef.current?.focus();
    }

    componentDidMount() {
        if (this.context.interfaceStore.ExecutionEnvironment.canUseDOM) {
            document.body.classList.add(styles.modalOpen);
        }

        document.addEventListener("keydown", this.onKeyDown);
        this.focusFirstElement();
    }

    componentWillUnmount() {
        if (this.context.interfaceStore.ExecutionEnvironment.canUseDOM && this.context.modalStore.stack.length === 0) {
            document.body.classList.remove(styles.modalOpen);
        }

        document.removeEventListener("keydown", this.onKeyDown);

        this.props.closeEffect && this.props.closeEffect();
        this.state.previousFocus && this.state.previousFocus.focus();
    }

    render() {
        const {index, size = "lg", type = "default", theme = "classic"} = this.props;

        let backdropZIndex = this.baseZIndex + index * 10,
            modalZIndex = backdropZIndex + 1;

        const closeClassName = classNames({
            [styles.close]: true,
            [styles.closeTransparent]: theme === 'transparent',
            [styles.closeAdmin]: type === 'admin',
            [this.props.closeButtonClassName || ""]: this.props.closeButtonClassName,
        });
        const modalContentClassName = classNames({
            [styles.modalContent]: true,
            [styles.modalContentTransparent]: theme === 'transparent',
            [this.props.className || ""]: this.props.className,
        });
        const modalClassName = classNames({
            [styles.modal]: true,
            [styles.modalTransparent]: theme === 'transparent',
            [this.props.modalClassName || ""]: this.props.modalClassName,
        });
        const dialogClassName = classNames({
            [styles.modalDialog]: true,
            [styles.modalDialogTransparent]: theme === 'transparent',
            [this.props.dialogClassName || ""]: this.props.dialogClassName,
            [styles.modalSm]: size === "sm",
            [styles.modalMd]: size === "md",
            [styles.modalLg]: size === "lg",
            [styles.modalSchoolblocks]: size === "sb",
            [styles.modalXl]: size === "xl",
        });
        const modalContainerClassName = classNames({
            [styles.modalContainer]: true,
            [styles.modalContainerTransparent]: theme === 'transparent',
            [this.props.containerClassName || ""]: this.props.containerClassName,
        });

        const ButtonComponent = this.props.closeButtonComponent ? cloneElement(this.props.closeButtonComponent, {
            ...this.props.closeButtonComponent.props,
            "aria-label": "Close",
            onClick: this.closeModal,
        }, this.props.closeButtonComponent.props.children) : <Button
            className={closeClassName}
            onClick={this.closeModal}
            id="modalCloseButton"
            aria-label="Close"
        >
            <FontAwesome ariaHidden={true} prefix={'fas'} name={'fa-times'}/>
        </Button>

        return (
            <div>
                <ModalBackdrop
                    active={this.state.active}
                    onMouseDown={this.onMouseDown}
                    opaqueBackground={true}
                    zIndex={backdropZIndex}
                />
                <div
                    ref="modal"
                    className={modalClassName}
                    style={{zIndex: modalZIndex}}
                    onMouseDown={this.onMouseDown}
                    tabIndex={-1}
                    role="dialog"
                    aria-modal="true"
                >
                    {theme === 'transparent' && <Button
                        className={closeClassName}
                        onClick={this.closeModal}
                        id="modalCloseButton"
                        aria-label="Close"
                    >
                        <FontAwesome ariaHidden={true} prefix={'fas'} name={'fa-circle-xmark'}/>
                    </Button>}
                    <div
                        className={dialogClassName}
                        style={this.props.width ? {maxWidth: this.props.width} : {}}
                        role="document"
                    >
                        <CSSTransition timeout={0} appear={true} in={this.state.active}
                                       classNames={this.props.transitionClassNames || transitionClassNames}>
                            <div tabIndex={0}
                                 ref={this.modalRef}
                                 className={modalContainerClassName}>
                                {theme !== 'transparent' && ButtonComponent}
                                <div tabIndex={0}
                                     className={modalContentClassName}>
                                    <ErrorBoundary>
                                        {this.props.children}
                                    </ErrorBoundary>
                                </div>
                                <span tabIndex={0} onFocus={this.focusFirstElement}/>
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal
